export default ["Addiction Medicine",
  "Allied Health",
  "Anaesthesiology",
  "Blood Bank",
  "Bone Marrow Transplant",
  "Cardio Thoracic and Vascular Surgery",
  "Cardiology",
  "CCU",
  "Chest Medicine",
  "Chiropractics",
  "Clinical Genetics",
  "Clinical pharmacology",
  "Clinical Psychology",
  "Cosmetic Dentistry",
  "Cosmetic Surgery",
  "Critical Care",
  "Dental Implants and Cosmetic Dentistry",
  "Dental Surgery",
  "Dermatology",
  "Dermatology Venereology and Leprology",
  "Developmental Medicine",
  "Diabetology",
  "Diagnostic Radiology",
  "Diet and Nutrition",
  "Electrophysiology",
  "Emergency Medicine",
  "Endocrinology",
  "Endodontics",
  "ENT",
  "Facial Plastic Surgery",
  "Family Medicine",
  "Gastroenterology",
  "Gastrointestinal Oncology",
  "Gastrosurgery",
  "General Medicine",
  "General Surgery",
  "General Surgery and Laparoscopic Surgery",
  "Geriatrics",
  "Gynaecology Oncology",
  "Gynaecology only",
  "Haematology",
  "HDU",
  "Head and Neck Surgery",
  "Head and Neck Surgical Oncology",
  "Heart Transplant",
  "Hemato Oncology",
  "Hepato Pancreato Biliary Surgery",
  "Immunology and Allergy",
  "Infectious Disease Medicine",
  "Integrative Oncology",
  "Intensive Care Medicine",
  "Internal Medicine",
  "Interventional Radiology",
  "Laparoscopic and Robotic Surgery",
  "Medical Gastroenterology",
  "Medical Oncology",
  "MICU",
  "Neonatal Surgery",
  "Nephrology",
  "Neuro ICU",
  "Neuro Surgery",
  "Neurology",
  "Neurology and Neuro Surgery",
  "Neuropsychiatry",
  "Neurosurgery",
  "NICU",
  "Nuclear Medicine",
  "Nursing",
  "Nursing Midwifery",
  "Obstetrics and Gynecology",
  "Obstetrics only",
  "Occupational and environmental medicine",
  "Oncology",
  "Ophthalmology",
  "Oral and Maxillofacial Surgery",
  "Ortho Dontia",
  "Ortho Oncology",
  "Orthopedic Surgery",
  "Orthopedics",
  "Orthopedics and Spine Surgery",
  "Orthopedics and Sports Medicine",
  "Orthopedics and Upper Limb Surgery",
  "Osteopathy",
  "PACU",
  "Paediatric Emergency Medicine",
  "Paediatrics",
  "Paediatrics - ENT",
  "Paediatrics – Nephrology",
  "Paediatrics – Neurology",
  "Paediatrics – Surgery",
  "Neonatology",
  "Pain and Palliation Oncology",
  "Pain Medicine",
  "Palliative Medicine",
  "Pathology",
  "Pediatric Dentistry",
  "Pediatric Endocrinology",
  "Pediatric Oncology",
  "Pediatric Ophthalmology and Squint",
  "Pediatric Pulmonology",
  "Periodontia",
  "Periodontology and Implantology",
  "Peripheral Vascular Surgery",
  "Pharmacy",
  "Physical Medicine and Rehabilitation",
  "Physician and Diabetology",
  "Physiotherapy",
  "Physiotherapy and Rehabilitation",
  "PICU",
  "Plastic and Cosmetic Surgery",
  "Plastic Surgery",
  "Podiatry",
  "Prosthodontics and Implants",
  "Psychiatry",
  "Public Health",
  "Pulmonology",
  "Radiation Oncology",
  "Radiology",
  "Reproductive Endocrinology and Infertility",
  "Respiratory Allergy and Sleep Medicine",
  "Rheumatology",
  "Sexual Health Medicine",
  "SICU",
  "Speech and Swallow Rehabilitation",
  "Spine Surgery",
  "Sports Medicine",
  "Surgical Gastroenterology",
  "Surgical Oncology",
  "TICU",
  "Uro Oncology",
  "Urogynaecology",
  "Urology"]