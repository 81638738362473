import React from 'react'
import SuccessTickSvg from './SuccessTickSvg'

export default () => {
  return (
    <div style={{ backgroundColor: "#A4DCA9", height: "100vh", textAlign: 'center', }}>
      <div style={{ position: "relative", top: "4em" }}>
        <img src="/CliniQLogo.png" style={{ width: "8em", margin: "auto" }} />
      </div>

      <div style={{ position: "relative", top: "8em", color: "#FFFFFF" }}>
        <div style={{ height: "4em", marginBottom: "2.5em" }} >
          <SuccessTickSvg height="inherit" />
        </div>
        <h2 style={{ margin: 0 }}>Thank you!</h2>
        {/* <h4>Your application has been submitted.</h4> */}
        <h4 style={{ lineHeight: "1.4" }}>Thank you for showing interest to provide Tele Consultation on our Clinivantage Platform!</h4>
        <h6 style={{ lineHeight: "1.4" }}>We will review your application and contact you shortly.</h6>
      </div>

      <div className="footer-div">
        <img src="/poweredLogo.png" style={{ height: "100%", margin: "auto" }} />
      </div>
    </div>
  )
}