import React from "react";

const svgStyle = {
  margin: 'auto',
  background: '#F7F9F9',
  display: 'block',
  shapeRendering: 'auto'
}

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={svgStyle} width="204px" height="204px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="translate(79,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="1" transform="scale(1.2681 1.2681)">
            <animateTransform attributeName="transform" type="scale" begin="-0.875s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.875s" />
          </circle>
        </g>
      </g><g transform="translate(70.50609665440987,70.50609665440987)">
        <g transform="rotate(45)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.875" transform="scale(1.3306 1.3306)">
            <animateTransform attributeName="transform" type="scale" begin="-0.75s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.75s" />
          </circle>
        </g>
      </g><g transform="translate(50,79)">
        <g transform="rotate(90)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.75" transform="scale(1.3931 1.3931)">
            <animateTransform attributeName="transform" type="scale" begin="-0.625s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.625s" />
          </circle>
        </g>
      </g><g transform="translate(29.493903345590123,70.50609665440987)">
        <g transform="rotate(135)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.625" transform="scale(1.4556 1.4556)">
            <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s" />
          </circle>
        </g>
      </g><g transform="translate(21,50)">
        <g transform="rotate(180)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.5" transform="scale(1.0181 1.0181)">
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.375s" />
          </circle>
        </g>
      </g><g transform="translate(29.493903345590116,29.493903345590123)">
        <g transform="rotate(225)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.375" transform="scale(1.0806 1.0806)">
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.25s" />
          </circle>
        </g>
      </g><g transform="translate(49.99999999999999,21)">
        <g transform="rotate(270)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.25" transform="scale(1.1431 1.1431)">
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.125s" />
          </circle>
        </g>
      </g><g transform="translate(70.50609665440987,29.493903345590116)">
        <g transform="rotate(315)">
          <circle cx="0" cy="0" r="5" fill="#ff727d" fillOpacity="0.125" transform="scale(1.2056 1.2056)">
            <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" />
            <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s" />
          </circle>
        </g>
      </g>
    </svg>
  )
}