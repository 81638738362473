import React from 'react';
import Amplify from 'aws-amplify';
import DoctorRegistration from './DoctorRegistration'
// import SampleForm from './SampleForm'
import SaveSuccessMessage from './SaveSuccessMessage'
import SampleAutoComp from './SampleAutoComp'

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const App = () => < DoctorRegistration />

export default App;