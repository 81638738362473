import React from "react";

// export default () => {
//   return (
//     <svg xmlSpace="preserve" viewBox="0 0 100 100" y="0" x="0" xmlns="http://www.w3.org/2000/svg" id="Capa_1"
//       version="1.1" width="259px" height="259px" xmlnsXlink="http://www.w3.org/1999/xlink" style={{
//         width: "100%", height: "100%", backgroundSize: "initial", backgroundRepeatY: "initial",
//         backgroundRepeatX: "initial", backgroundPositionY: "initial", backgroundPositionX: "initial", backgroundOrigin: "initial",
//         backgroundColor: "initial", backgroundClip: "initial",
//         backgroundAttachment: "initial"
//       }} ><g style={{ transformOrigin: "50% 50%", transform: "rotate(0deg) scale(0.8, 0.8)" }} >
//         <circle stroke-miterlimit="10" stroke-width="8" stroke="#333" fill="none" r="40" cy="50" cx="50"
//           style={{ stroke: "rgb(114, 152, 120)" }} ></circle>
//         <g >
//           <path fill="#abbd81" d="M47.3 66.4L73.7 40c1.8-1.8 1.8-4.6 0-6.4-1.8-1.8-4.6-1.8-6.4 0L44.1 56.8 32.7 45.4c-1.8-1.8-4.6-1.8-6.4 0-1.8 1.8-1.8 4.6 0 6.4l14.6 14.6c.9.9 2 1.3 3.2 1.3s2.3-.5 3.2-1.3z" style={{ fill: "rgb(114, 152, 120)" }} ></path></g>
//       </g></svg>
//   )
// }

export default ({ height }) => {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512", height, fill: "#729878" }} xmlSpace="preserve">
      <g>
        <g>
          <path d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713
			c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481
			c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286
			C504.873,89.761,504.886,77.54,497.36,69.995z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407
			c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296
			C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256
			C512,245.342,503.362,236.703,492.703,236.703z"/>
        </g>
      </g>
    </svg>
  )
}