/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://danbg5dbvbbgxgqjw6xyupdhoy.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5l6l3znsbrhzxhxufxmusscrpy",
    "aws_cognito_identity_pool_id": "ap-south-1:25261846-69d7-41ec-9a1b-a63cd5099e4a",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_yPxAeUFfg",
    "aws_user_pools_web_client_id": "4j1d7puimktgo8es7al9nbbeuc",
    "oauth": {},
    "aws_user_files_s3_bucket": "teledocsproofdocsdev223722-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
