/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSubmitEmailToDoc = /* GraphQL */ `
  mutation SendSubmitEmailToDoc($input: SendEmailDoctorInput!) {
    sendSubmitEmailToDoc(input: $input)
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor($input: CreateDoctorInput!) {
    createDoctor(input: $input) {
      id
      mob
      email
      fName
      lName
      fullName
      gender
      speciality
      subSpeciality
      clinicName
      addressOne
      addressTwo
      country
      state
      city
      zip
      registrationId
      registrationCouncil
      registrationYear
      highestQualification
      university
      degreeYear
      experienceYears
      identityProofDoc {
        bucket
        key
        region
      }
      registrationProofDoc {
        bucket
        key
        region
      }
      clinicAddressProofDoc {
        bucket
        key
        region
      }
      clinicLogo {
        bucket
        key
        region
      }
      currency
      newAptCharge
      followAptCharge
      status
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor($input: UpdateDoctorInput!) {
    updateDoctor(input: $input) {
      id
      mob
      email
      fName
      lName
      fullName
      gender
      speciality
      subSpeciality
      clinicName
      addressOne
      addressTwo
      country
      state
      city
      zip
      registrationId
      registrationCouncil
      registrationYear
      highestQualification
      university
      degreeYear
      experienceYears
      identityProofDoc {
        bucket
        key
        region
      }
      registrationProofDoc {
        bucket
        key
        region
      }
      clinicAddressProofDoc {
        bucket
        key
        region
      }
      clinicLogo {
        bucket
        key
        region
      }
      currency
      newAptCharge
      followAptCharge
      status
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor($input: DeleteDoctorInput!) {
    deleteDoctor(input: $input) {
      id
      mob
      email
      fName
      lName
      fullName
      gender
      speciality
      subSpeciality
      clinicName
      addressOne
      addressTwo
      country
      state
      city
      zip
      registrationId
      registrationCouncil
      registrationYear
      highestQualification
      university
      degreeYear
      experienceYears
      identityProofDoc {
        bucket
        key
        region
      }
      registrationProofDoc {
        bucket
        key
        region
      }
      clinicAddressProofDoc {
        bucket
        key
        region
      }
      clinicLogo {
        bucket
        key
        region
      }
      currency
      newAptCharge
      followAptCharge
      status
    }
  }
`;
